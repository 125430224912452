import React, { useState, useEffect } from "react";
import {
  deleteDraftItem,
  getOverallDraftDataPosted
} from "../../helpers/backend_helper";
import { useNavigate, Link } from "react-router-dom";
import Spinner from "../../components/Spinner/index";

const Main = ({ categoryId, handleRefresh, submissionNo }) => {
  let navigate = useNavigate();
  const [labels, setLabels] = useState([]);
  const [draftData, setDraftData] = useState([]);
  const [draftTotals, setDraftTotals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let nf = new Intl.NumberFormat();

  const fetchDraftData = async () => {
    setIsLoading(true);
    setDraftData([]);

    try {
      await getOverallDraftDataPosted(categoryId).then((res) => {
        if (res === false) {
          navigate("/logout");
        } else {
          setDraftData(res["data"]);
          setLabels(res["labels"])
          setDraftTotals(res["totals"])
          
        }
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setIsLoading(false);
  };


  useEffect(() => {
    fetchDraftData();
  }, [categoryId, submissionNo]);

  
  const handleDelete = async (data) => {
    await deleteDraftItem(data).then((res) => console.log(res.status));

    handleRefresh()
  
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Shared Status</th>
              <th>Region</th>
              <th>Period</th>
              <th>Year</th>

              {labels ? (
                <>
                  {labels.map((item, index) => (
                    <th className="bg-gray-200" key={item.data_point_id}>
                      {item.data_point__label}
                    </th>
                  ))}
                </>
              ) : null}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {draftData.map((item, index) => (
              <tr key={index}>
                <td style={{ width: "2rem" }}>{index + 1}</td>
                <td style={{ width: "5rem" }}>
                  {JSON.stringify(item.harmonization_status) === "true" ? (
                    <span className="badge bg-success">
                      {" "}
                      Exposed to other BIs
                    </span>
                  ) : (
                    <span className="badge bg-info"> Internal Access Only</span>
                  )}
                </td>
                <td style={{ width: "10rem" }}>
                  <h6>{item.region}</h6>
                </td>
                <td style={{ width: "10rem" }}>
                  <h6>{item.period}</h6>
                </td>
                <td style={{ width: "10rem" }}>
                  <h6>
                    {item.period_order === 1
                      ? `${item.year}/${item.year + 1}`
                      : `${item.year - 1}/${item.year}`}
                  </h6>
                </td>
                {Object.entries(item.data_point_value_pairs).map(
                  ([key, value]) => (
                    <td key={key} className="font-bold" style={{ width: "10rem" }}>
                      {value}
                    </td>
                  )
                )}

                <td style={{ width: "10rem", textAlign: "center" }}>
                  <Link
                    className="btn-sm-danger"
                    onClick={(e) => handleDelete(item.records)}
                  >
                    Remove
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td style={{ width: "2rem" }}></td>
              <td style={{ width: "5rem" }}></td>
              <td style={{ width: "10rem" }}></td>
              <td style={{ width: "10rem" }}></td>

              {draftTotals ? (
                <>
                  <td
                    style={{
                      width: "10rem",
                      fontWeight: "bold",
                      fontSize: "15px",
                      fontStyle: "oblique",
                    }}
                  >
                    TOTALS
                  </td>
                  {draftTotals.map((item) => (
                    <td
                    key={item.data_point_id}
                      style={{
                        width: "10rem",
                        fontWeight: "bold",
                        fontSize: "15px",
                        fontStyle: "oblique",
                      }}
                    >
                      {nf.format(item.value)}
                    </td>
                  ))}
                </>
              ) : (
                ""
              )}
              <td></td>
            </tr>
          </tfoot>
        </table>
      )}
    </>
  );
};

export default Main;
