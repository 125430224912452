const prod = {
    url: {
        //ECC ip
        // API_URL:"https://api.nps-ecc.com/api/v1"

        //BSAAT IP
        API_URL:"https://api.bsaat.org/api/v1"   

        //BSAAT ZanzibarIP
        // API_URL:"https://api-zanzi.bsaat.org/api/v1"  
    }
};

const dev = {
    url: {
        API_URL: "http://127.0.0.1:8000/api/v1"
    }
}

export const config = process.env.NODE_ENV === "development" ? dev : prod;