import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/plots";

const MultiLineChart = ({ loadedData }) => {
  const data = loadedData;
  const config = {
    data,
    color:["#006D5B", "#0047AB","#800020", "#4B0082", "#D69408", "#708090"],
    xField: 'label',
    yField: 'value',
    seriesField: 'type',
    // yAxis: {
    //   label: {
    //     // 数值格式化为千分位
    //     formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    //   },
    // },
  };
  return <Line {...config} />;
};

export default MultiLineChart;
