import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column } from "@ant-design/plots";

const BarChart = ({ loadedData, color }) => {
  const data = loadedData;
  const config = {
    data,
    xField: "label",
    yField: "value",
    color:color,
    label: {
      position: "middle",
      style: {
        fill: "#006D5B",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
      },
    },
    yAxis: {
      label: {
        // 数值格式化为千分位
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    meta: {
      type: {
        alias: "label",
      },
      value: {
        alias: "value",
      },
    },
  };
  return <Column {...config} />;
};

export default BarChart;
