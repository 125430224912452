import React, { Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Paper } from "@mui/material";
import * as Yup from "yup";
import Stack from "@mui/material/Stack";
import Logo from "../../assets/images/logo.png";
import withRouter from "../../components/Common/withRouter";
import { Alert, Form, Dropdown, Label, Row, Input } from "reactstrap";
// actions
import { loginUser } from "../../store/actions";
import Spinner from "../../components/Spinner/index";
import { FileTextOutlined } from "@ant-design/icons";
//redux
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Field, FormikProvider } from "formik";
import { getProjectDetails } from "../../helpers/backend_helper";
import { Modal } from "reactstrap";
import Questionnaire1 from "../Survey/GeneralQuestionnaire";
import Questionnaire2 from "../Survey/SpecificQuestionnaire";

const theme = createTheme();

let secretQuestionnaireCode = "Bsaat2023$";

const SignIn = (props) => {
  const [projectDetails, setProjectDetails] = useState({});
  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);
  const [openQuestionnaire, setOpenQuestionnaire] = useState(false);
  const [origin, setOrigin] = useState("");
  const [code, setCode] = useState();
  const [isAllowed, setIsAllowed] = useState(false);
  const [errorQuestionnaire, setErrorQuestionnaire] = useState(false);
  const [hasQuestionnaires, setHasQuestionnaires] = useState(false);
  
  const handleQuestionnaire = (questionnaireType) => {
    setOpenQuestionnaire(true);
    setOrigin(questionnaireType);
  };

  const handleCloseQuestionnaire = () => {
    setOpenQuestionnaire(false);
  };

  const handleChange = (event) => {
    setCode(event.target.value);
    if (event.target.value === secretQuestionnaireCode) {
      setIsAllowed(true);
    } else {
      setErrorQuestionnaire(true);

      setTimeout(() => {
        setErrorQuestionnaire(false);
      }, 2000);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    },
  });

  const { error } = useSelector((state) => ({
    error: state.login.error,
  }));

  const fetchProjectDetails = async () => {
    try {
      await getProjectDetails().then((res) => {
        setProjectDetails(res);

        if (localStorage.getItem("projec") == null) {
          localStorage.setItem("project", res[0].description);
          localStorage.setItem("details", res[0].details);
          localStorage.setItem("logo", res[0].logo);
          localStorage.setItem("tracksFiles", res[0].tracks_files.toString());
          localStorage.setItem(
            "collectsStatistics",
            res[0].collects_statistics.toString()
          );
          localStorage.setItem("visualizesData", res[0].visualizes_data.toString());
          localStorage.setItem(
            "requiresDataExposure",
            res[0].requires_data_exposure.toString()
          );
          localStorage.setItem("hasQuestionnaires", res[0].has_questionnaires.toString());
          setHasQuestionnaires(res[0].has_questionnaires.toString())
        }

        setIsLoaded(true);
      });
    } catch (error) {
      console.log("Error fetching project details", error);
    }
  };

  useEffect(() => {
    fetchProjectDetails();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Modal
          size="lg"
          isOpen={openQuestionnaire}
          toggle={() => {
            setOpenQuestionnaire(!openQuestionnaire);
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title">Questionnaire</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {isAllowed === false ? (
              <div>
                <Label>Please enter the provided code (Check email)</Label>
                <Input value={code} type="password" onChange={handleChange} />
              </div>
            ) : origin === "general_questionnaire" ? (
              <Questionnaire1 questionnaireType="general_questionnaire" />
            ) : origin === "specific_questionnaire" ? (
              <Questionnaire2 questionnaireType="specific_questionnaire" />
            ) : (
              ""
            )}
          </div>

          {errorQuestionnaire ? (
            <Alert color="danger">
              <div>
                Wrong code. Please contact the distributor of this questionnaire
              </div>
            </Alert>
          ) : null}

          <div className="modal-footer">
            <button
              type="button"
              className="btn-danger"
              data-bs-dismiss="modal"
              onClick={handleCloseQuestionnaire}
            >
              Close
            </button>
          </div>
        </Modal>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          component={Paper}
          elevation={6}
          square
        >
          {hasQuestionnaires? (
            <div>
              <button
                style={{ marginTop: "1%", marginLeft: "1%" }}
                type="button"
                className="btn-info"
                data-bs-dismiss="modal"
                onClick={() => handleQuestionnaire("general_questionnaire")}
              >
                General Questionnaire
              </button>

              <button
                style={{ marginTop: "1%", marginLeft: "1%" }}
                type="button"
                className="btn-primary"
                data-bs-dismiss="modal"
                onClick={() => handleQuestionnaire("specific_questionnaire")}
              >
                Specific Questionnaire
              </button>
            </div>
          ) : (
            ""
          )}

          <Box
            pt={15}
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justify: "center",
            }}
          >
            <Box
              component="img"
              m={4}
              sx={{
                height: 233,
                width: 350,
                maxHeight: { xs: 167, md: 167 },
                maxWidth: { xs: 167, md: 167 },
              }}
              alt="logo"
              src={Logo}
            />
            <Typography variant="h5">
              {projectDetails[0] && isLoaded ? (
                <span>{projectDetails[0].description}</span>
              ) : null}
            </Typography>

            <Typography variant="h6">
              <Suspense fallback={<div>Loading ...</div>}>
                {projectDetails[0] && isLoaded ? (
                  <span>{projectDetails[0].details}</span>
                ) : (
                  <Spinner />
                )}
              </Suspense>
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          // component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              m={4}
              sx={{
                height: 233,
                width: 350,
                maxHeight: { xs: 50, md: 50 },
                maxWidth: { xs: 50, md: 50 },
              }}
              alt="logo"
              src={Logo}
            />
            <Typography component="h1" variant="h5">
              <span className="login-title">SIGN IN </span>
            </Typography>

            <Form
              className="form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              {error ? (
                <Alert color="danger">
                  <div>{error}</div>
                </Alert>
              ) : null}
              <div className="bg-white-400 flex flex-col items-center justify-center  py-2">
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-10">
                  <input name="csrfToken" type="hidden" />
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email or Username"
                    sx={{ height: 40, m: 3, pr: 6 }}
                    value={validation.values.email}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={
                      validation.touched.email &&
                      Boolean(validation.errors.email)
                    }
                    helperText={
                      validation.touched.email && validation.errors.email
                    }
                  />
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    sx={{ height: 40, m: 3, pr: 6 }}
                    value={validation.values.password}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    error={
                      validation.touched.password &&
                      Boolean(validation.errors.password)
                    }
                    helperText={
                      validation.touched.password && validation.errors.password
                    }
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value="remember"
                        color="primary"
                        sx={{ ml: 3 }}
                      />
                    }
                    label="Remember me"
                  />
                  <div className="bg-white shadow-md rounded px-6 pt-6 pb-8 mb-10">
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      style={{ marginBottom: "20px" }}
                    >
                      <Button
                        color="primary"
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ height: 50 }}
                      >
                        {validation.isSubmitting ? "Please wait..." : "Login"}
                      </Button>
                    </Stack>

                    <Grid container>
                      <Grid item>
                        <Link to="/dashboard" variant="body2">
                          Forgot password?
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </Form>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default withRouter(SignIn);

SignIn.propTypes = {
  history: PropTypes.object,
};
